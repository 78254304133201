import axios from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import ability from '@/libs/acl/ability'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import VueCookies from 'vue-cookies'

export default {
  namespaced: true,
  state: {
    userData: {
      id: '',
      uid: '',
      email: '',
      forename: '',
      surname: '',
      avatar: '',
      userRole: '',
      tag: '',
      ability: [],
      isAdmin: '',
      company: '',
      address1: '',
      address2: '',
      skype: '',
      postcode: '',
      website: '',
      phone: '',
      news_sub: 0,
      product_updates_sub: 0,
      city: '',
      country: '',
      region: '',
      user_status: 0,
      account_type: 0,
      active: 0,
      isUserAffiliate: 0,
      limited: false,
      limitedPermissions: [],
      ed: [],
    },
    userAvatar: {
      user_id: '',
      avatar: '',
    },
    admin: {
      userData: {},
      token: '',
    },
    impersonate: false,
    countries: null,
    packages: null,
    regions: null,
    activePackages: null,
  },
  getters: {
    getCountryByCode: state => code => state.countries.find(obj => obj.code === code),
    getPackageByUID: state => uid => state.packages.find(obj => obj.uid === uid),
  },
  mutations: {
    // mutation for limited uuser cookie
    SET_LIMITED_USER(state, payload) {
      state.userData.limited = payload
    },
    IMPERSONATE(state) {
      state.admin.userData = JSON.parse(JSON.stringify(state.userData))
      state.admin.token = useJwt.getToken()
      state.impersonate = true
    },
    EXIT_IMPERSONATE(state) {
      useJwt.setToken(state.admin.token)

      state.userData = JSON.parse(JSON.stringify(state.admin.userData))
      state.impersonate = false

      state.admin.userData = {}
      state.admin.token = ''
    },
    UPDATE_USER_DATA(state, payload) {
      const {
        id, uid, email, forename, surname, avatar, administrator, is_affiliate, isAdmin, company, address1, address2, skype, postcode, website,
        phone, news_sub, city, country, region, user_status, account_type, active, package_id, tag, product_updates_sub,
      } = payload

      state.userData.id = id
      state.userData.uid = uid
      state.userData.email = email
      state.userData.forename = forename
      state.userData.surname = surname
      state.userData.avatar = avatar
      state.userData.userRole = administrator === 1 ? 'Admin' : 'User'
      state.userData.tag = tag
      state.userData.isAdmin = isAdmin
      state.userData.company = company
      state.userData.address1 = address1
      state.userData.address2 = address2
      state.userData.skype = skype
      state.userData.postcode = postcode
      state.userData.website = website
      state.userData.phone = phone
      state.userData.news_sub = news_sub
      state.userData.product_updates_sub = product_updates_sub
      state.userData.city = city
      state.userData.country = country
      state.userData.region = region
      state.userData.user_status = user_status
      state.userData.account_type = account_type
      state.userData.active = active
      state.userData.package_id = package_id
      state.userData.ed = payload.ed

      // eslint-disable-next-line camelcase
      if (is_affiliate === 1) state.userData.userRole = 'Affiliate'
      if (is_affiliate === -1) state.userData.isUserAffiliate = 1
    },
    UPDATE_USER_ABILITY(state) {
      let ab = []
      const platform = { action: 'manage', subject: 'Platform' }
      if (state.userData.userRole === 'Admin') {
        ab = [
          { action: 'read', subject: 'Auth' },
          { action: 'manage', subject: 'Admin' },
          { action: 'manage', subject: 'General' },
          { action: 'manage', subject: 'Billing' },
          { action: 'manage', subject: 'Ticket' },
          { action: 'manage', subject: 'Message' },
          { action: 'manage', subject: 'Ad-User' },
          // { action: 'manage', subject: 'CurrentInvoice' },
        ]
      } else if (state.userData.userRole === 'Affiliate') {
        ab = [
          { action: 'read', subject: 'Auth' },
          { action: 'manage', subject: 'General' },
          { action: 'manage', subject: 'Ticket' },
          { action: 'manage', subject: 'Affiliate' },
          { action: 'manage', subject: 'Af-User' },
        ]
      } else if (state.userData.limited) {
        ab = [
          { action: 'read', subject: 'Auth' },
          { action: 'manage', subject: 'General' },
          { action: 'manage', subject: 'Ticket' },
          { action: 'manage', subject: 'Message' },
          { action: 'manage', subject: 'Ad-User' },
          { action: 'manage', subject: 'Af-User' },
        ]
        if (state.userData.limitedPermissions) {
          state.userData.limitedPermissions.forEach(item => {
            ab.push({ action: 'manage', subject: item })
          })
        }
      } else {
        ab = [
          { action: 'read', subject: 'Auth' },
          { action: 'manage', subject: 'General' },
          { action: 'manage', subject: 'User' },
          { action: 'manage', subject: 'Billing' },
          { action: 'manage', subject: 'Ticket' },
          { action: 'manage', subject: 'Message' },
          { action: 'manage', subject: 'Ad-User' },
          { action: 'manage', subject: 'Af-User' },
          { action: 'manage', subject: 'AffiliateUser' },
          { action: 'manage', subject: 'CurrentInvoice' },
        ]
        if (state.userData.user_status >= 4 && state.userData.user_status !== 6) ab.push(platform)
      }
      state.userData.ability = ab
    },
    UPDATE_USER_AVATAR(state, payload) {
      state.userAvatar = payload
    },
    SET_COUNTRIES(state, payload) {
      if (!state.countries) state.countries = payload
    },
    SET_REGIONS(state, payload) {
      state.regions = payload
    },
    SET_USER_AVATAR(state, payload) {
      const isSet = Number.isNaN(Number(state.userAvatar.id))
      if (isSet) {
        state.userAvatar = payload
      }
    },
    SET_PACKAGES(state, payload) {
      if (!state.packages) state.packages = payload
    },
    SET_PACKAGES_ACTIVE(state, payload) {
      if (!state.activePackages) state.activePackages = payload
    },
    SET_LIMITED_PERMISSIONS(state, payload) {
      if (state.userData.limited) {
        state.userData.limitedPermissions = JSON.parse(payload)
      }
    },
    CLEAR_USER_DATA(state) {
      state.userData = {
        id: '',
        uid: '',
        email: '',
        forename: '',
        surname: '',
        avatar: '',
        userRole: '',
        tag: '',
        ability: [],
        isAdmin: '',
        company: '',
        address1: '',
        address2: '',
        city: '',
        skype: '',
        postcode: '',
        website: '',
        phone: '',
        region: '',
        news_sub: 0,
        product_updates_sub: 0,
        user_status: 0,
        account_type: 1,
        isUserAffiliate: 0,
      }
    },
  },
  actions: {
    setLimited({ commit }) {
      const limitedCookie = VueCookies.get('limited')
      if (limitedCookie) {
        commit('SET_LIMITED_USER', true)
      }
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/auth/logout')
          .then(res => {
            if (res.data) {
              commit('CLEAR_USER_DATA')
              localStorage.clear()
              // delete the limited cookie
              VueCookies.remove('limited')
              ability.update([])

              resolve(res.data)
            }
          })
          .catch(err => {
            commit('CLEAR_USER_DATA')
            localStorage.clear()
            ability.update([])

            reject(err)
          })
      })
    },
    vatInfo(ctx, payload) {
      return new Promise((resolve, reject) => {
        const uri = `/api/custom-invoices/checkvat/${payload.country_code}/${payload.uid}`
        axios
          .get(uri)
          .then(res => {
            if (res.data) {
              resolve(res.data)
            }
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    validate({ commit, state }, token) {
      useJwt.setToken(token)
      return new Promise((resolve, reject) => {
        const customAxios = axios
        customAxios.interceptors.response.use(response => response, error => {
          // const pageUrl = new URL(location.href) // eslint-disable-line
          // (pageUrl.pathname === '/home' || pageUrl.pathname === '/' || pageUrl.pathname === '')

          if (error.message === 'Network Error') {
            const url = window.location.href
            const payload = encodeURIComponent(window.btoa(url)) // eslint-disable-line
            const param = '?i=' // eslint-disable-line
            // window.location = `${process.env.VUE_APP_PORTAL}login${param}${payload}`
          }
          return reject(error)
        })

        customAxios.get('/sanctum/csrf-cookie').then(() => {
          customAxios
            .get('/api/users/me?init=true')
            .then(res => {
              if (res?.data) {
                let userAvatar = {}
                if (!res.data?.avatar?.avatar) {
                  userAvatar.user_id = res.data.avatar.user_id
                  userAvatar.avatar = '@/assets/images/avatars/placeholder.png'
                } else {
                  userAvatar = res.data.avatar
                }

                commit('SET_COUNTRIES', res.data.countries)
                commit('SET_REGIONS', res.data.regions)
                commit('SET_PACKAGES', res.data.packages)
                commit('SET_PACKAGES_ACTIVE', res.data.activePackages)
                commit('SET_USER_AVATAR', userAvatar)
                commit('UPDATE_USER_DATA', res.data.userData)
                if (res.data.permissions) {
                  commit('SET_LIMITED_PERMISSIONS', res.data.permissions)
                }
                commit('UPDATE_USER_ABILITY')

                if (res.data.impersonate) {
                  commit('IMPERSONATE')
                }
                localStorage.clear()
                if (res.data?.package_id) {
                  state.userData.package_id = res.data.package_id
                }
                localStorage.setItem('userData', JSON.stringify(state.userData))
                useJwt.setToken(res.data.accessToken)

                ability.update(state.userData.ability)

                resolve(res.data)
              } else {
                localStorage.clear()
              }
            })
        })
      })
    },
    impersonate({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/impersonate', { id })
          .then(res => {
            if (res.data) {
              commit('IMPERSONATE')
              commit('UPDATE_USER_DATA', res.data.userData)
              commit('UPDATE_USER_ABILITY')

              localStorage.clear()
              localStorage.setItem('userData', JSON.stringify(state.userData))
              useJwt.setToken(res.data.accessToken)
              ability.update(state.userData.ability)

              resolve(res.data)
            }
          })
          .catch(err => { reject(err) })
      })
    },
    exitImpersonation({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.post('/exit-impersonation')
          .then(() => {
            commit('EXIT_IMPERSONATE')
            const tempObj = state.userData
            tempObj.is_affiliate = 0
            commit('UPDATE_USER_ABILITY')
            localStorage.clear()
            localStorage.setItem('userData', JSON.stringify(state.userData))
            ability.update(state.userData.ability)
            resolve(state.userData)
          })
          .catch(err => { reject(err) })
      })
    },
    async attempt({ dispatch }) {
      return new Promise(resolve => {
        const limitedCookie = VueCookies.get('limited')
        if (limitedCookie) {
          dispatch('setLimited')
        }
        const token = localStorage.getItem('accessToken')
        dispatch('validate', token)
          .then(() => {
            resolve(dispatch)
          })
          .catch(() => {
            router.push('/unauthenticated')
          })
      })
    },
    activate(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/auth/activate/${id}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    resendActivationEmail(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/auth/resend-activation/${id}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    updateProfile({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/account/updateuserprofile', payload)
          .then(response => {
            if (response.data) {
              commit('UPDATE_USER_DATA', payload)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    async updateRegions({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/account/getregions', payload)
          .then(response => {
            if (response.data) {
              commit('SET_REGIONS', response.data)
              resolve(response.data)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateUserAvatar({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/account/updateavatar', payload)
          .then(response => {
            if (response.data) {
              commit('UPDATE_USER_AVATAR', payload)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
  },
}
